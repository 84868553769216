<div class="social d-flex justify-content-around">
    <a
        class="social-media-link instagram"
        href="https://www.instagram.com/fainin_sharing/"
        rel="noreferrer"
        target="_blank"
        role="button"
        attr.aria-label="{{ 'label.socialMediaRow.instagram' | translate }}"
        (click)="doEmitEvent('Instagram')">
        <img style="width: 32px; height: 32px" src="/assets/icon/social/instagram.svg" alt="fainin-instagram" />
    </a>
    <a
        class="social-media-link youtube"
        href="https://www.youtube.com/@fainin"
        rel="noreferrer"
        target="_blank"
        role="link"
        attr.aria-label="{{ 'label.socialMediaRow.youtube' | translate }}"
        (click)="doEmitEvent('YouTube')">
        <fa-icon size="2x" [icon]="['fab', 'youtube']"></fa-icon>
    </a>
    <a
        class="social-media-link x"
        href="https://www.twitter.com/fainin_sharing"
        rel="noreferrer"
        target="_blank"
        role="link"
        attr.aria-label="{{ 'label.socialMediaRow.twitter' | translate }}"
        (click)="doEmitEvent('Twitter')">
        <fa-icon size="2x" [icon]="['fab', 'x-twitter']"></fa-icon>
    </a>
    <a
        class="social-media-link facebook"
        href="https://www.facebook.com/FaininSharing"
        rel="noreferrer"
        target="_blank"
        role="button"
        attr.aria-label="{{ 'label.socialMediaRow.facebook' | translate }}"
        (click)="doEmitEvent('Facebook')">
        <fa-icon size="2x" [icon]="['fab', 'facebook']"></fa-icon>
    </a>
    <a
        class="social-media-link linkedin"
        href="https://www.linkedin.com/company/fainin/"
        rel="noreferrer"
        target="_blank"
        role="button"
        attr.aria-label="{{ 'label.socialMediaRow.linkedin' | translate }}"
        (click)="doEmitEvent('LinkedIn')">
        <fa-icon size="2x" [icon]="['fab', 'linkedin']"></fa-icon>
    </a>
    <a
        class="social-media-link discord"
        href="https://discord.gg/BeAxxEAUXe"
        rel="noreferrer"
        target="_blank"
        role="button"
        attr.aria-label="{{ 'label.socialMediaRow.discord' | translate }}"
        (click)="doEmitEvent('Discord')">
        <fa-icon size="2x" [icon]="['fab', 'discord']"></fa-icon>
    </a>
    <a
        class="social-media-link tiktok"
        href="https://www.tiktok.com/@fainin_sharing"
        rel="noreferrer"
        target="_blank"
        role="button"
        attr.aria-label="{{ 'label.socialMediaRow.tiktok' | translate }}"
        (click)="doEmitEvent('TikTok')">
        <img style="width: 32px; height: 32px" src="/assets/icon/social/tiktok.svg" alt="fainin-tiktok" />
    </a>
</div>
